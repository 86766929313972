import base_api from './token'

/*
    账号搜索
    game 游戏名
    condition 限制条件，如下
    {
        "cards_search" : ["231004","243001"],
        "resource_amount" : [10,0,0,30000],
    }
*/
function accountSearch(condition) {
  const include = {}
  condition.include.forEach((card) => {
    if (!include[card]) {
      include[String(card)] = 1
    } else {
      include[String(card)] += 1
    }
  })
  condition.include = include

  return new Promise((resolve, reject) => {
    base_api
      .post('/queryer', {
        target: 'account',
        action: 'search',
        request: {
          condition,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status == 1) {
            if (res.data.response == null) {
              resolve([])
            } else {
              resolve(res.data.response)
            }
          } else {
            reject('request failed: ' + res.data.message)
          }
        } else {
          reject('request error, status code: ' + res.status)
        }
      })
      .catch((error) => {
        catchError(error, reject)
      })
  })
}

/*  
    账号查询
    code 账号编号
*/
function accountQuery(code) {
  return new Promise((resolve, reject) => {
    base_api
      .post('/queryer', {
        target: 'account',
        action: 'query',
        request: {
          code: code,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status == 1) {
            // 解析时间
            res.data.response.expire = new Date(res.data.response.expire)
            resolve(res.data.response)
          } else {
            reject('request failed: ' + res.data.message)
          }
        } else {
          reject('request error ,status code: ' + res.status)
        }
      })
      .catch((error) => {
        catchError(error, reject)
      })
  })
}

/*  
    账号锁定
    code 账号编号
*/
function accountLock(code) {
  return new Promise((resolve, reject) => {
    base_api
      .post('/queryer', {
        target: 'account',
        action: 'lock',
        request: {
          code: code,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status == 1 && !res.data.response.locked) {
            // 解析时间
            res.data.response.expire = new Date(res.data.response.expire)
            res.data.response.status = true
          } else {
            res.data.response.message = res.data.message
            res.data.response.status = false
          }
          resolve(res.data.response)
        } else {
          reject('request error ,status code: ' + res.status)
        }
      })
      .catch((error) => {
        catchError(error, reject)
      })
  })
}
/*  
    账号查询
    code 账号提取
*/
function accountExtract(code, vouchers) {
  return new Promise((resolve, reject) => {
    base_api
      .post('/queryer', {
        target: 'account',
        action: 'extract_voucher',
        request: {
          code,
          vouchers,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status == 1 && !res.data.response.locked) {
            // 解析时间
            res.data.response.expire = new Date(res.data.response.expire)
            res.data.response.status = true
          } else {
            res.data.response = {}
            res.data.response.message = res.data.message
            res.data.response.status = false
          }
          resolve(res.data.response)
        } else {
          reject('request failed ,status code: ' + res.status)
        }
      })
      .catch((error) => {
        catchError(error, reject)
      })
  })
}

/*  
    订单查找
    code 按代金券查找订单
*/
function accountFind(voucher) {
  return new Promise((resolve, reject) => {
    base_api
      .post('/queryer', {
        target: 'account',
        action: 'query_extract_voucher',
        request: {
          voucher,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status == 1 && !res.data.response.locked) {
            // 解析时间
            res.data.response.expire = new Date(res.data.response.expire)
            res.data.response.status = true
          } else {
            res.data.response = {}
            res.data.response.message = res.data.message
            res.data.response.status = false
          }
          resolve(res.data.response)
        } else {
          reject('request failed ,status code: ' + res.status)
        }
      })
      .catch((error) => {
        catchError(error, reject)
      })
  })
}

// 应该可以吧
function catchError(error, reject) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    reject('请求出错：' + error.response.data + ' : ' + error.response.status + ' : ' + error.response.headers)
    console.error(error.response.data)
    console.error(error.response.status)
    console.error(error.response.headers)
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    reject('请求出错：' + error.request)
    console.error(error.request)
  } else {
    // Something happened in setting up the request that triggered an Error
    reject('请求出错：' + error.message)
    console.error('Error', error.message)
  }
}

export { accountSearch, accountQuery, accountLock, accountExtract, accountFind }
