<template>
  <el-row>
    <!-- 淘宝链接 -->
    <el-col :span="8">
      <div @click="toShop" class="top-bar-heiht flex-left-center magrin-left-window">
        <i class="el-icon-s-shop color-white margin-left-20" style="font-size: 23px" />
        <p class="color-white margin-left-10 pointer" style="font-size: 15px">
          {{ getShopName }}
        </p>
      </div>
    </el-col>

    <el-col :span="16" class="top-bar-heiht flex-right-center">
      <!-- 找回 -->
      <div @click="findOrder">
        <p class="color-white margin-right-20 pointer" style="font-size: 14px">
          {{ $t('message.QueryHisteryOrder') }}
        </p>
      </div>
      <!-- 切换游戏 -->
      <el-dropdown @command="toPage" v-if="false">
        <span class="color-white pointer margin-right-20"
          >{{ $t('message.ChangeGame') }}<i class="el-icon-arrow-down el-icon--right"></i
        ></span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="(item, index) in getGames"
            :key="index"
            :disabled="$route.params.id == item.id"
            :command="item.id"
          >
            {{ item.name }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- 切换语言 -->
      <el-dropdown @command="changeLang" v-if="false">
        <span class="color-white pointer margin-right-20" style="font-size: 14px"
          >{{ $t('message.language') }}<i class="el-icon-arrow-down el-icon--right"></i
        ></span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="(item, index) in getLocales"
            :key="index"
            :disabled="$t('message.lable') == item.lable"
            :command="item.lable"
          >
            {{ item.language }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- 主页 -->
      <div v-if="!isHome" @click="toHome">
        <p class="color-white margin-right-20 pointer" style="font-size: 14px">
          {{ $t('message.home') }}
        </p>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { setup } from '@/locales'
import { accountFind } from '@/network/account.js'
export default {
  data() {
    return {
      isHome: true,
    }
  },
  computed: {
    // 从Vuex获取游戏列表
    getGames() {
      return this.$store.getters.getShopInfo.game_list
    },
    // 从Vuex获取语言列表
    getLocales() {
      return this.$store.state.locales
    },
    getShopName() {
      return this.$store.getters.getShopInfo.name
    },
    getShopLink() {
      return this.$store.getters.getShopInfo.main_url
    },
  },
  methods: {
    //跳转到游戏页面
    toPage(command) {
      // 跳转游戏页面
      this.$router.push(`/game/${command}`)
      // 整页显示加载状态
      let loading = this.$loading.service({
        lock: true,
        text: 'Loading',
        // spinner: 'el-icon-loading',
        target: document.querySelector('#content-search'),
      })

      // 获取完整游戏信息
      this.$store
        .dispatch('setGameInfo', command)
        .then((res) => {
          // 获取成功
          loading.close()
          console.warn(res)
        })
        .catch((err) => {
          // 获取失败
          loading.close()
          this.$message.error(err)
        })
    },
    //跳转到主页
    toHome() {
      this.$router.push(`/home`)
    },
    // 待修改内容，代金券找回
    findOrder() {
      this.$prompt(this.$t('message.please_input_voucher'), this.$t('message.retrieve'), {
        confirmButtonText: this.$t('message.confirm'),
        cancelButtonText: this.$t('message.cancel'),
        inputPattern: /[A-Z0-9]{18}-[0-9]{2}/,
        inputErrorMessage: this.$t('message.voucher_incorrect'),
      })
        .then(({ value }) => {
          let loading = this.$loading({
            lock: true,
            background: 'rgb(255,255,255,0.6)',
          })
          let athis = this
          accountFind(value)
            .then((data) => {
              loading.close()
              athis.$confirm(
                `${athis.$t('message.here_account_info')}:</br>${athis.$t('message.code')}:${data.code}</br>${athis.$t(
                  'message.id'
                )}:${data.transfer.id}  ${athis.$t('message.password')}:${data.transfer.password} `,
                athis.$t('message.retrieve_success'),
                {
                  confirmButtonText: athis.$t('message.confirm'),
                  type: 'success ',
                  showCancelButton: false,
                  dangerouslyUseHTMLString: true,
                }
              )
            })
            .catch((error) => {
              athis.$message({
                type: 'error',
                message: error,
              })
              athis.findOrder()
            })
        })
        .catch(() => {
          athis.$message({
            type: 'info',
            message: this.$t('message.input_cancel'),
          })
        })
    },
    //手动切换语音
    changeLang(command) {
      // val === 0 ? setup("zh") : setup("en");
      setup(command)
      this.$message({
        message: this.$t('message.success'),
        type: 'success',
      })
    },
    //判断是否为主页
    comfirmHome() {
      this.isHome = this.$route.path.substr(1) == 'home'
    },
    toShop() {
      window.open(this.getShopLink, '_blank')
    },
  },
  watch: {
    $route() {
      this.comfirmHome()
    },
  },
  created: function () {
    this.comfirmHome()
  },
}
</script>

<style>
.top-bar-heiht {
  height: 60px;
}
.flex-left-center {
  display: flex;
  flex-flow: row left;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.flex-right-center {
  display: flex;
  flex-flow: row right;
  justify-content: flex-end;
  align-items: center;
}
.margin-left-10 {
  margin-left: 10px;
}
.margin-left-20 {
  margin-left: 20px;
}
.margin-right-10 {
  margin-right: 10px;
}
.margin-right-20 {
  margin-right: 20px;
}
.color-white {
  color: #efefef;
}
.pointer {
  cursor: pointer;
}
</style>
