import Vue from 'vue'
import VueRouter from 'vue-router'

// import HomePage from '@/views/home/homePage'
// import GamePage from '@/views/game/gamePage'

// 懒加载
const HomePage = () => import(/* webpackChunkName: "group-home" */ '@/views/home/homePage')
const GamePage = () => import(/* webpackChunkName: "group-home" */ '@/views/game/gamePage')

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    redirect: '/home',
  },
  {
    path: '/home',
    component: HomePage,
  },
  {
    path: '/game/:name',
    component: GamePage,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
