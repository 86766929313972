<template>
  <div>
    <el-container>
      <el-header>
        <main-menu></main-menu>
      </el-header>
      <el-main>
        <div id="comtent-box" class="content-box">
          <router-view />
        </div>
      </el-main>
      <el-footer height="20px">powered by Vue</el-footer>
    </el-container>
  </div>
</template>

<script>
import MainMenu from '@/components/common/mainMenu'
import checkWebpFeature from "@/assets/js/webp.js"

export default {
  name: 'BasePage',
  components: {
    MainMenu,
  },
  data() {
    return {}
  },
  watch: {
    // 语言切换
    // language: function (val) {       //侦听单选按钮的变化，从而进行切换语言
    //   // val === 0 ? this.$i18n.locale = 'zh' : this.$i18n.locale = 'en';
    //   val === 0 ? setup("zh") : setup("en");
    // }
  },
  created: function () {
    // 整页显示加载状态
    let loading = this.$loading({
      lock: true,
      text: 'Loading',
    })
    // 设定图片格式
    checkWebpFeature('lossy', (_, isSupported)=>{
      this.$store.commit('setWebpSupport', isSupported)
      this.$store.commit('setIconSize', 'large')

      // 更新商铺数据
      this.$store
        .dispatch('setShopInfo')
        .then(() => {
          // 获取成功
          loading.close()
        })
        .catch((err) => {
          // 获取失败
          loading.close()
          this.$message.error(err)
        })
      }
    )
  },
}
</script>

<style>
/* *{
  margin:0;
} */
/* html,body{
  height: 100%;

} */
.el-header {
  background: #3c6085;
  padding: 0;
}
.content-box {
  min-height: calc(100vh - 120px);
}
.el-footer {
  text-align: center;
  /* line-height: 20px; */
  /* margin-top: -20px; */
}
</style>
