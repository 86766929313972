/*
 * @Author: Fkst5in
 * @Date: 2021-08-23 21:43:20
 * @LastEditors: Fkst5in
 * @LastEditTime: 2021-08-23 21:43:45
 * @Description:
 * @FilePath: /be.taaga.me/home/fkstein/workspace/nodejs/vue/taaga.me/src/network/token.js
 */
import axios from 'axios'

const account_instance = axios.create({
  timeout: 80000,
  baseURL: process.env.VUE_APP_API,
})

account_instance.interceptors.request.use(
  (config) => {
    if (localStorage.token) {
      //判断token是否存在
      config.headers.Authorization = localStorage.token // 将token设置成请求头
      config.headers.From = window.location.hostname // 标记
    }
    if (process.env.NODE_ENV === 'development') {
      config.headers.From = 'taaga.me'
    }
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

account_instance.interceptors.response.use(
  (response) => {
    if (response.headers.authorization) {
      localStorage.token = response.headers.authorization
    }
    return response
  },
  (err) => {
    return Promise.reject(err)
  }
)

export default account_instance
