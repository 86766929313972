import base_api from './token'

// 获取所有游戏信息
function getShopInfo(mediaOption) {
  return new Promise((resolve, reject) => {
    base_api
      .post('/queryer', {
        target: 'game',
        action: 'all',
      }, {
        headers:{
          "Accept-Image-Type": mediaOption.type,
          "Accept-Game-Icon-Size": mediaOption.gameIconSize,
        }
      })
      .then((res) => {
        if (res.status == 200) {
          if (res.data.status == 1) {
            let names = Object.keys(res.data.response.games)
            let games = []
            // 设定 logo地址
            names.forEach((gamename) => {
              // res.data.response.games[gamename].logo =
              //   process.env.VUE_APP_CFIMAGES + res.data.response.games[gamename].logo + '/512x'
              games.push(res.data.response.games[gamename])
            })
            // for (let index = 0; index < names.length; index++) {
            // 	const name = names[index];
            // 	res.data.response.games[name].logo =
            // 		process.env.VUE_APP_CFIMAGES +
            // 		res.data.response.games[name].logo +
            // 		"/512x";
            // }

            // 重排 game_list
            games.sort((a, b) => b.id - a.id)
            res.data.response.game_list = games
            resolve(res.data.response)
          } else {
            reject('请求失败：' + res.data.message)
          }
        } else {
          reject('请求出错，状态码：' + res.status)
        }
      })
      .catch((error) => {
        catchError(error, reject)
      })
  })
}

function getCardList(name, mediaOption) {
  return new Promise((resolve, reject) => {
    base_api
      .post('/queryer', {
        target: 'game',
        action: 'info',
        request: {
          game_name: name,
        }
      },{
        headers:{
          "Accept-Image-Type": mediaOption.type,
          "Accept-Card-Icon-Size": mediaOption.cardIconSize,
          "Accept-Game-Icon-Size": mediaOption.gameIconSize,
        }
      })
      .then((res) => {
        if (res.status == 200) {
          if (res.data.status == 1) {
            // 处理角色卡图地址 无后缀
            for (let key in res.data.response.cards) {
              res.data.response.cards[key].src = res.data.response.cards[key].image
                // process.env.VUE_APP_CFIMAGES + res.data.response.cards[key].image + '/256x'
              // process.env.VUE_APP_STATIC +
              // "image/" +
              // res.data.response.name +
              // "/" +
              // res.data.response.cards[key].id;
            }
            resolve(res.data.response)
          } else {
            reject('请求失败：' + res.data.message)
          }
        } else {
          reject('请求出错，状态码：' + res.status)
        }
      })
      .catch((error) => {
        catchError(error, reject)
      })
  })
}

// 应该可以吧
function catchError(error, reject) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    reject('请求出错：' + error.response.data + ' : ' + error.response.status + ' : ' + error.response.headers)
    console.error(error.response.data)
    console.error(error.response.status)
    console.error(error.response.headers)
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    reject('请求出错：' + error.request)
    console.error(error.request)
    console.error(error.message)
  } else {
    // Something happened in setting up the request that triggered an Error
    reject('请求出错：' + error.message)
    console.error('Error', error.message)
  }
}

export { getShopInfo, getCardList }
