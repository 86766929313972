<template>
  <div id="app" class="background">
    <base-page></base-page>
  </div>
</template>

<script>
import { setup } from './locales'
import BasePage from '@/components/common/basePage'

export default {
  name: 'App',
  components: {
    BasePage,
  },
  created: () => {
    console.log('lalala')
    console.log('缓存测试')

    console.warn('缓存测试')
    console.error('缓存测试')
    let lang = navigator.language || navigator.userLanguage
    lang = lang.substr(0, 2)
    setup(lang)
    // 页面挂载时更新商铺数据
    //this.$store.dispatch('setShopInfo');
  },
}
</script>

<style>
@import 'assets/css/base.css';

.background {
  background: #f5f5f5;
}
.color-black {
  color: #545c64;
}
</style>
