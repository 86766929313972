import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './locales'
import clipboard from 'clipboard'
// import qs from 'querystring'
import waterfall from 'vue-waterfall2'
import 'element-ui/lib/theme-chalk/index.css'

// 引入collapse动画
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'
Vue.component(CollapseTransition.name, CollapseTransition)

import axios from 'axios'
import axios_account from './network/token'
// 引入 element-ui
import {
  Button,
  Radio,
  RadioGroup,
  Container,
  Header,
  Main,
  Footer,
  Icon,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Message,
  Col,
  Row,
  Card,
  MessageBox,
  Steps,
  Step,
  Divider,
  Tabs,
  TabPane,
  InputNumber,
  Loading,
  Table,
  TableColumn,
  Tooltip,
  Pagination,
  Form,
  FormItem,
  Input,
  Link,
  Tag,
  Badge,
} from 'element-ui'
// 使用一下，注意有些组件用法不一样
Vue.use(Button)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Container)
Vue.use(Header)
Vue.use(Main)
Vue.use(Footer)
Vue.use(Icon)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Col)
Vue.use(Row)
Vue.use(Card)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Divider)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(InputNumber)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Tooltip)
Vue.use(Pagination)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Link)
Vue.use(Tag)
Vue.use(waterfall)
Vue.use(Badge)

Vue.prototype.$message = Message
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$loading = Loading.service
Vue.prototype.clipboard = clipboard
Vue.prototype.$axios = axios
Vue.prototype.$account = axios_account
// Vue.prototype.$qs = qs;
// Vue.prototype.$waterfall = waterfall

Vue.config.productionTip = true

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
