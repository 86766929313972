import Vue from 'vue'
import Vuex from 'vuex'

import { getShopInfo, getCardList } from '@/network/game.js'
import { accountSearch } from '@/network/account.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    stepActived: 0,
    shopInfo: {},
    gameInfo: {},
    gameName: '',
    cardList: [],
    cardSelectMajor: [],
    cardSelectMinor: [],
    URLimit: {
      Min: 1,
      Max: 99,
    },
    locales: [
      {
        lable: 'zh',
        language: '简体中文',
      },
      {
        lable: 'en',
        language: 'English',
      },
      {
        lable: 'ja',
        language: '日本语',
      },
    ],
    searchResult: [],
    purchaseAccount: '',
    webpSupport: false,
    cardIconSize: 'medium',
    gameIconSize: 'xxlarge',
  },
  getters: {
    // 获取游戏名
    getGameName(state) {
      return (lang) => {
        if (state.gameInfo.game_name) {
          switch (lang) {
            case 'zh':
              return state.gameInfo.game_name[0]
            case 'en':
              return state.gameInfo.game_name[1]
            case 'ja':
              return state.gameInfo.game_name[2]
          }
        }
        return ' -_- '
      }
    },
    // 账号详情显示，数量资源类型
    getResourceType(state) {
      if (state.gameInfo.resource_type) {
        return state.gameInfo.resource_type
      }
      return []
    },
    // 卡图显示时，获取资源信息
    getCardInfo(state) {
      return (id) => state.gameInfo.cards[id]
    },
    // 获取针对相应游戏的店铺细节设定
    getShopDetail(state) {
      return state.shopInfo.shop.detail[state.gameInfo.name]
    },
    // 获取账号搜索结果
    getResult(state) {
      return state.searchResult
    },
    // 获取当前游戏信息
    getGameInfo(state) {
      return state.gameInfo
    },
    // 获取最高稀有度角色列表
    getCardMajor(state) {
      return state.cardList.filter((i) => i.rarity == 3)
    },
    // 获取次高稀有读角色列表
    getCardMinor(state) {
      return state.cardList.filter((i) => i.rarity == 2)
    },
    // 判断角色是否被激活
    getActive(state) {
      return (level, id) => {
        switch (level) {
          case 3:
            return state.cardSelectMajor.includes(id)
          case 2:
            return state.cardSelectMinor.includes(id)
        }
      }
    },
    // 返回一个从搜索结果中搜索特定id帐号的函数(进入账号购买页面时需要)
    getAccount(state) {
      return state.searchResult.find((o) => o.code == state.purchaseAccount)
    },
    getShopInfo(state) {
      return state.shopInfo
    },
  },
  mutations: {
    setShopInfo(state, payload) {
      state.shopInfo = payload
      // 需要判断游戏信息是否存在，否则可能导致查询游戏详情时设置的角色信息被覆盖
      if (state.gameName != '' && !state.gameInfo) {
        state.gameInfo = state.shopInfo.game_list.filter((game) => game.name == state.gameName)[0]
      }
    },
    setGameInfo(state, name) {
      state.gameInfo = state.shopInfo.game_list.filter((game) => game.name == name)[0]
      // 按角色加入时间排序（待定
      // state.cardList.sort((a,b) => b.join_time - a.join_time)
    },
    setGameName(state, name) {
      state.gameName = name
    },
    setCardList(state, game) {
      state.gameInfo = game
      for (var i in game.cards) {
        state.cardList.push(game.cards[i])
      }
    },
    setResult(state, payload) {
      state.searchResult = payload
    },
    selectCardMajor(state, id) {
      let index = -1
      ;(index = state.cardSelectMajor.indexOf(id)) == -1
        ? state.cardSelectMajor.push(id)
        : state.cardSelectMajor.splice(index, 1)
    },
    selectCardMinor(state, id) {
      let index = -1
      ;(index = state.cardSelectMinor.indexOf(id)) == -1
        ? state.cardSelectMinor.push(id)
        : state.cardSelectMinor.splice(index, 1)
    },

    updateURLimit(state, payload) {
      state.URLimit[payload.type] = payload.value
    },

    clearSelect(state) {
      state.cardSelectMajor = []
      state.cardSelectMinor = []
      state.URLimit.Min = 1
      state.URLimit.Max = state.gameInfo.URLimit + 1
    },
    setStep(state, step) {
      state.stepActived = step
    },
    setPurchase(state, id) {
      state.purchaseAccount = id
    },
    setWebpSupport(state, isSupport) {
      state.webpSupport = isSupport
    },
    setIconSize(state, size) {
      state.IconSize = size
    },
    clearGameInfo(state) {
      state.gameInfo = {}
      state.cardList = []
    }
  },
  actions: {
    // 帐号查询结果
    accountSearch({ commit, state }) {
      let condition = {
        max_source: [state.URLimit.Max - 0 + 1],
        include: state.cardSelectMajor.concat(state.cardSelectMinor),
        game_name: state.gameInfo.name,
        exclude: [],
      }
      return new Promise((resolve, reject) => {
        accountSearch(condition)
          .then(function (data) {
            commit('setResult', data)
            resolve()
          })
          .catch((error) => {
            console.error('account search failed:')
            console.error(error)
            reject('账号搜索失败，请检查网络并重试') // todo 翻译
          })
      })
    },
    // 店铺信息查询
    setShopInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getShopInfo({
          type: state.webpSupport ? 'webp' : 'png',
          gameIconSize: state.gameIconSize
        })
          .then((data) => {
            commit('setShopInfo', data)
            resolve()
          })
          .catch((error) => {
            console.error('get shop info failed:')
            console.error(error)
            reject('店铺信息获取失败，请联系客服') // todo 翻译
          })
      })
    },
    // 游戏信息查询
    setCardList({ state, commit }) {
      return new Promise((resolve, reject) => {
        getCardList(state.gameName, {
          type: state.webpSupport ? 'webp' : 'png',
          gameIconSize: state.gameIconSize,
          cardIconSize: state.cardIconSize
        })
          .then((data) => {
            commit('setCardList', data)
            resolve()
          })
          .catch((error) => {
            console.error('get game info failed:')
            console.error(error)
            reject('游戏信息获取失败，请联系客服') // todo 翻译
          })
      })
    },
  },
  modules: {},
})
